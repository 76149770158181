import React, { useState, useEffect } from "react";
import Chessground from "@react-chess/chessground";
import { Chess } from "chess.js";
import "chessground/assets/chessground.base.css";
import "chessground/assets/chessground.brown.css";
import "chessground/assets/chessground.cburnett.css";
import Button from "react-bootstrap/Button"; // Import Button from React Bootstrap

const Hint = ({ showHint, after_pgn, fen, orientation }) => {
  const initialFen =
    fen === "8/8/8/8/8/8/8/8"
      ? "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1"
      : fen;

  const [game, setGame] = useState(new Chess(initialFen));
  const [currentMove, setCurrentMove] = useState(0);

  // Make sure after_pgn is always treated as an array.
  const moves = Array.isArray(after_pgn) ? after_pgn : [];

  const [config, setConfig] = useState({
    fen: fen,
    orientation: fen.includes("w") ? "black" : "white", // Use the 'orientation' prop to set the orientation
    movable: {
      free: false,
      color: "both",
      dests: new Map(),
    },
    draggable: {
      enabled: false,
    },
  });
  useEffect(() => {
    setConfig((prevState) => ({
      ...prevState, // Spread the previous state to maintain other properties
      fen: fen, // Update 'fen' with the new 'fen'
      orientation: fen.includes("w") ? "black" : "white", // Conditionally set 'orientation' based on the new 'fen'
    }));
  }, [fen]);
  useEffect(() => {
    const newGame = new Chess(initialFen);

    // Now that we've ensured moves is an array, .slice() and .forEach() can be safely used.
    moves.slice(0, currentMove).forEach((moveStr) => {
      // Assuming each move is a string in standard algebraic notation (SAN)
      newGame.move(moveStr, { sloppy: true }); // Use sloppy mode to allow various inputs
    });

    setGame(newGame);
    setConfig((prevConfig) => ({
      ...prevConfig,
      fen: newGame.fen(),
    }));
  }, [currentMove, moves, initialFen]);

  const handleForward = () => {
    if (currentMove < moves.length) {
      setCurrentMove((prevMove) => prevMove + 1);
    }
  };

  const handleBackward = () => {
    if (currentMove > 0) {
      setCurrentMove((prevMove) => prevMove - 1);
    }
  };

  return (
    <div className="hints" style={{ display: showHint ? "block" : "none" }}>
      <img src="/hint1.webp" alt="hint" className="happy" />
      <h4 className="alert-heading">Hint for you!</h4>
      <Button
        variant="secondary"
        onClick={handleBackward}
        disabled={currentMove === 0}
        className="backwordbtn"
      >
        ←
      </Button>{" "}
      {/* Adding a space between buttons */}
      <Button
        variant="primary"
        onClick={handleForward}
        disabled={currentMove === moves.length}
        className="forwardbtn"
      >
        →
      </Button>
      <Chessground
        className="hintboard img-fluid ml-5"
        contained={false}
        config={config}
      />
    </div>
  );
};

export default Hint;
