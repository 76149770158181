import React, { useEffect, useContext } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import BrowserRouter
import { Container, Row, Col } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Checkout from "./pages/Checkout";
import Success from "./pages/Success";
import Cancel from "./pages/Cancel";
import { validate } from "./integrations/user";
import { UserContext } from "./contexts/UserContext"; // Import UserContext from the new file

import SideNav from "./components/SideNav";
import Home from "./pages/Home";
import Play from "./pages/Play";
import Edit from "./pages/Edit";
import Edit2 from "./pages/Edit2";
import Stats from "./pages/Stats";
import Profile from "./pages/Profile";
import ContactPage from "./pages/ContactPage";
import HintWithAlerts from "./pages/HintWithAlerts";
import Categories from "./pages/Categories";
import WinchesterChessClub from "./components/WinchesterChessClub";
import FreemantleShirleyChessClub from "./components/FreemantleShirleyChessClub";
import ChessCamps from "./components/ChessCamps";
import ChessClub from "./components/ChessClub";
import JuniorClubWinchester from "./components/JuniorClubWinchester";
import LichessTeamBattleInfo from "./components/LichessTeamBattleInfo";
import HJCABlitz from "./components/HJCABlitz";
import OnlineMeetings from "./components/OnlineMeetings";
import LandingPage from "./components/LandingPage";
import Calendar from "./components/Calendar";
import PrintPuzzle from "./pages/PrintPuzzle";
import EncorageLogin from "./pages/EncorageLogin";
import CookieConsent from "./components/CookieConsent";
import CookiePolicyModal from "./components/CookiePolicyModal";
import CookiePolicy from "./components/CookiePolicy";
import PrivacyPolicy from "./components/PrivacyPolicy";

// Replace with your own publishable key from the Stripe Dashboard
const stripePromise = loadStripe(
  "pk_live_51OqwYREqx1V56brjlfCmzkffqzBWBBgyREU6ur6n5Y6p15LsbSkYKJUpn0xhW48ggEZwrGJ4lWEX8pC0Mtu5fImj00neIwVyEw"
);

const App = () => {
  const user = useContext(UserContext);

  useEffect(() => {
    document.title = "Chesshood";
  }, []);

  return (
    <div className="app-container">
      <Container fluid>
        <Row>
          <Col xs={1} md={1} xl={2} className="pt-3 column1">
            <SideNav /> {/* Include SideNav component here */}
          </Col>
          <Col xs={11} md={11} xl={10} className="column2">
            {validate(user).length !== 0 ? (
              <Profile />
            ) : (
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/play" element={<Play />} />
                <Route path="/play/:id" element={<Play />} />
                <Route path="/edit" element={<Edit />} />
                <Route path="/edit/:id" element={<Edit />} />
                <Route path="/edit2/:id" element={<Edit2 />} />
                <Route path="/stats" element={<Stats />} />
                <Route path="/ContactPage" element={<ContactPage />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/HintWithAlerts" element={<HintWithAlerts />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/success" element={<Success />} />
                <Route path="/cancel" element={<Cancel />} />
                <Route path="/chessclub" element={<ChessClub />} />
                <Route path="/print/:puzzleid" element={<PrintPuzzle />} />
                <Route path="/encorageLogin" element={<EncorageLogin />} />
                <Route
                  path="/CookiePolicyModal"
                  element={<CookiePolicyModal />}
                />
                <Route path="/CookiePolicy" element={<CookiePolicy />} />
                <Route
                  path="/WinchesterChessClub"
                  element={<WinchesterChessClub />}
                />
                <Route
                  path="/FreemantleShirleyChessClub"
                  element={<FreemantleShirleyChessClub />}
                />
                <Route path="/ChessCamps" element={<ChessCamps />} />
                <Route
                  path="/JuniorClubWinchester"
                  element={<JuniorClubWinchester />}
                />
                <Route
                  path="/LichessTeamBattleInfo"
                  element={<LichessTeamBattleInfo />}
                />
                <Route path="/HJCABlitz" element={<HJCABlitz />} />
                <Route path="/OnlineMeetings" element={<OnlineMeetings />} />
                <Route path="/LandingPage" element={<LandingPage />} />
                <Route path="/Calendar" element={<Calendar />} />
                <Route path="*" element={<CookieConsent />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              </Routes>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

// Replace the old ReactDOM.render with createRoot
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <Router>
        {" "}
        {/* Wrap your App with BrowserRouter */}
        <App />
      </Router>
    </Elements>
  </React.StrictMode>
);

export default App;
