import { useRef, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Chessground from "@react-chess/chessground";
import { Chess, SQUARES } from "chess.js";
import UserFailures from "./UserFailures";
import CustomAlert from "../components/CustomAlert";
import ChessMoveIndicator from "../components/ChessMoveIndicator";
import InfoPuzzle from "./InfoPuzzle";
import PuzzlesJson from "../db/puzzles.json";

import {
  getPuzzle,
  getFirstPuzzle,
  checkPuzzleResult,
  getUserFailures,
} from "../integrations/APIClient";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";

import {
  showSuccess,
  showError,
  showHintMessage,
} from "../components/SuccessFailMessage";
import UserSuccesses from "./UserSuccesses";

import UserHints from "./UserHints";
import useAlert from "./useAlert"; // Ensure this path is correctly pointing to your useAlert hook.
import { Alert } from "react-bootstrap";

// Import UserContext
import { UserContext } from "../contexts/UserContext"; // Adjust the path as needed

// Play.js or any other file
import React from "react";
import { toDests, toColor, toPgnHistory, game } from "../utils/chessUtils"; // Adjust the path as needed
import Promotion from "../components/PromotionComponent"; // Adjust the path as needed

export default function Play() {
  const [category, setCategory] = useState("basic"); // Example category
  const [isHintClicked, setIsHintClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const puzzleID = useParams().id;
  const puzzle = useRef(null);
  const [gameFen, setGameFen] = useState("8/8/8/8/8/8/8/8");
  const [puzzleFinished, setPuzzleFinished] = useState(false);
  const moveNr = useRef(0);
  const [config, setConfig] = useState({});
  const [showHint, setShowHint] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    variant: "",
    message: "",
    success: "",
  });
  const [promoting, setPromoting] = useState(false);
  const randomIndex = Math.floor(Math.random() * PuzzlesJson.length);
  // Use UserContext to get the user data
  const user = useContext(UserContext);

  const showAlert = (message, variant) => {
    setAlertInfo({ show: true, variant, message, success });
  };

  // Function to hide the alert
  const hideAlert = () => {
    setAlertInfo({ ...alertInfo, show: false });
  };

  function checkPromotion(game, orig, dest) {
    const pgn = orig.concat(dest);
    if (!(game.get(orig).type === "p")) return pgn;
    if (
      (game.get(orig).color === "w" && orig[1] == 7 && dest[1] == 8) ||
      (game.get(orig).color === "b" && orig[1] == 2 && dest[1] == 1)
    ) {
      setPromoting(pgn);
      return false;
    }
    return pgn;
  }

  function promotion(pgn) {
    setPromoting(false);
    makeUserMove(pgn);
  }

  function updateGame(fen) {
    const newConfig = {
      fen: fen,
      orientation:
        puzzle.current.fenPosition.split(" ")[1] === "w" ? "black" : "white",
      turnColor: toColor(game),
      movable: {
        color: toColor(game),
        dests: toDests(game),
        events: {
          after: userMove,
        },
      },
    };

    setConfig(newConfig);
  }

  useEffect(() => {
    const loadPuzzle = async () => {
      console.log("Estado actual de user:", user);
      console.log("Estado actual de puzzleID:", puzzleID);

      try {
        if (user && puzzleID) {
          // Caso 1: Usuario logueado y puzzleID disponible
          console.log(
            "Usuario logueado, cargando puzzle desde el backend con ID:",
            puzzleID
          );
          const fetchedPuzzle = await getPuzzle(puzzleID);
          puzzle.current = fetchedPuzzle;
          puzzle.current.after_pgn = fetchedPuzzle.after_pgn.split(" ");
          setGameFen(fetchedPuzzle.fenPosition);
          game.load(fetchedPuzzle.fenPosition);
          setConfig({
            orientation: game.turn() === "b" ? "white" : "black",
          });
          updateGame(fetchedPuzzle.fenPosition);
          computerMove(true);
        } else if (user && !puzzleID) {
          // Caso 2: Usuario logueado pero no hay puzzleID
          console.log(
            "Usuario logueado pero no hay puzzleID. Cargando primer puzzle desde el backend."
          );
          const firstPuzzle = await getFirstPuzzle();
          if (firstPuzzle) {
            window.history.pushState(
              "",
              "",
              "/play/" + firstPuzzle["_id"]["$oid"]
            );
            puzzle.current = firstPuzzle;
            puzzle.current.after_pgn = firstPuzzle.after_pgn.split(" ");
            setGameFen(firstPuzzle.fenPosition);
            game.load(firstPuzzle.fenPosition);
            setConfig({
              orientation: game.turn() === "b" ? "white" : "black",
            });
            updateGame(firstPuzzle.fenPosition);
            computerMove(true);
          }
        } else if (!user) {
          // Caso 3: Usuario no logueado
          console.log("Usuario no logueado, cargando puzzle desde JSON.");

          const defaultPuzzle = PuzzlesJson[randomIndex];
          puzzle.current = defaultPuzzle;
          puzzle.current.after_pgn = defaultPuzzle.after_pgn.split(" ");
          setGameFen(defaultPuzzle.fenPosition);
          game.load(defaultPuzzle.fenPosition);
          setConfig({
            orientation: game.turn() === "b" ? "white" : "black",
          });
          updateGame(defaultPuzzle.fenPosition);

          computerMove(true);
        }
      } catch (error) {
        console.error("Error while loading puzzle:", error);
      }
    };

    // Ensure that `user` is defined before attempting to load the puzzle
    if (user !== undefined) {
      loadPuzzle();
    }
  }, [puzzleID, user]); // Dependencias: `puzzleID` o `user`

  function makeUserMove(pgn, hint = false) {
    console.log(puzzle.current["_id"]["$oid"], toPgnHistory(game));
    console.log("hint");

    if (hint) {
      setIsHintClicked(true);
      console.log(UserContext);
      if (UserContext === "Guest") {
        const currentPuzzleId = puzzle.current["_id"]["$oid"];
        const fetchedPuzzle = PuzzlesJson.find(
          (p) => p["_id"]["$oid"] === currentPuzzleId
        );

        if (fetchedPuzzle) {
          setAlertInfo({
            show: true,
            variant: "warning",
            user: "Guest User",
            addedOrRestRating: 0,
            puzzleSuccessMessage: `Hint: ${fetchedPuzzle.description}`,
            success: "hint",
            fen: fetchedPuzzle.fenPosition,
            after_pgn: fetchedPuzzle.after_pgn,
            config: config,
          });
        } else {
          setAlertInfo({
            show: true,
            variant: "danger",
            user: "Guest User",
            addedOrRestRating: 0,
            puzzleSuccessMessage: "Hint not found for this puzzle.",
            success: "error",
            fen: puzzle.current.fenPosition,
            after_pgn: "",
            config: config,
          });
        }
      } else {
        // For registered users, try fetching the hint from the backend
        checkPuzzleResult(
          puzzle.current["_id"]["$oid"],
          toPgnHistory(game),
          true
        )
          .then((result) => {
            setAlertInfo({
              show: true,
              variant: "warning",
              user: result.user,
              addedOrRestRating: result.addedOrRestRating,
              puzzleSuccessMessage: "Here's a hint for you!",
              success: "hint",
              fen: puzzle.current.fenPosition,
              after_pgn: puzzle.current.after_pgn,
              config: config,
            });
          })
          .catch((error) => {
            // If there's an error getting the hint from the backend,
            // fallback to a random puzzle from PuzzlesJson

            setAlertInfo({
              show: true,
              variant: "warning",
              user: "User",
              addedOrRestRating: 0,
              puzzleSuccessMessage:
                "Failed to fetch hint from backend. Try again later or solve the current puzzle.",
              success: "hint",
              fen: puzzle.current.fenPosition,
              after_pgn: puzzle.current.after_pgn,
              config: config,
            });
          });
        setPuzzleFinished(true);
        return; // Stop further processing after showing a hint for registered users
      }
    }

    if (pgn === puzzle.current.after_pgn[moveNr.current]) {
      game.move(pgn);

      if (moveNr.current == puzzle.current.after_pgn.length - 1) {
        //chesckPuzzleResult(puzzle.current["_id"]["$oid"], toPgnHistory(game)); if ot logged then error

        checkPuzzleResult(puzzle.current["_id"]["$oid"], toPgnHistory(game))
          .then((result) => {
            // const resultObject = JSON.parse(result); // Assuming result needs parsing
            setAlertInfo({
              show: true,
              variant: "success",
              user: result.user,
              addedOrRestRating: result.addedOrRestRating,
              puzzleSuccessMessage: puzzle.current.success_message,
              success: "true",
            });
          })
          .catch((error) => {
            // Update state to show error message
            setAlertInfo({
              show: true,
              variant: "success",
              user: "User",
              addedOrRestRating: "2",
              puzzleSuccessMessage: puzzle.current.success_message,
              success: "true",
            });
          });
        updateGame(game.fen());
        setPuzzleFinished(true);
      } else {
        moveNr.current++;
        computerMove();
      }
    } else {
      // Handles incorrect moves or puzzle failures
      checkPuzzleResult(puzzle.current["_id"]["$oid"], toPgnHistory(game))
        .then((result) => {
          setAlertInfo({
            show: true,
            variant: "error",
            user: result.user,
            addedOrRestRating: result.addedOrRestRating,
            puzzleSuccessMessage: puzzle.current.fail_message,
            success: "false",
          });
        })
        .catch((error) => {
          setAlertInfo({
            show: true,
            variant: "error",
          });
        });

      setPuzzleFinished(true);
      updateGame(game.fen());
    }
  }

  function repeat() {
    window.location.reload(false);
  }

  function userMove(orig, dest, metadata) {
    const pgn = checkPromotion(game, orig, dest);
    if (!pgn) return;
    makeUserMove(pgn);
  }

  function computerMove(firstLoad = false) {
    console.log("firstLoad", firstLoad);
    console.log("moveNr.current", moveNr.current);
    console.log("puzzle.current.after_pgn", puzzle.current.after_pgn);
    const move = puzzle.current.after_pgn[firstLoad ? 0 : moveNr.current];

    if (firstLoad) {
      moveNr.current = 1;
    } else {
      moveNr.current++;
    }
    console.log("move", move);
    game.move(move);
    updateGame(game.fen());
  }

  function nextPuzzle() {
    window.location.replace("/play");
  }

  function hint() {
    setShowHint(!showHint);
    makeUserMove(moveNr.current, true);
  }

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // When the component mounts, add a class to the body
    document.body.classList.add("play-active");

    // Cleanup function when the component unmounts
    return () => {
      document.body.classList.remove("play-active");
    };
  }, []); // Runs only once when the component mounts

  return (
    <>
      <Row>
        <Col xs={12} sm={8} md={9} lg={8} xl={8} xxl={8} className="column3">
          <Promotion pgn={promoting} onPromotion={promotion} />
          <Chessground
            contained={false}
            config={config}
            className="img-fluid ml-5 chesscolumn"
          />
        </Col>
        <Col
          xs={12}
          sm={4}
          md={3}
          lg={4}
          xl={4}
          xxl={4}
          className="infoPuzzle column4"
        >
          {alertInfo.show && (
            <CustomAlert
              variant={alertInfo.variant}
              message={alertInfo.message}
              onClose={hideAlert}
              user={alertInfo.user}
              addedOrRestRating={alertInfo.addedOrRestRating}
              puzzleSuccessMessage={alertInfo.puzzleSuccessMessage}
              success={alertInfo.success}
              fen={alertInfo.fen}
              after_pgn={alertInfo.after_pgn}
            />
          )}
          <Row className="ml-3">
            <Col
              xs={user ? 2 : 3}
              xl={user ? 2 : 3}
              className={user ? "col col2" : "col col1 colstatics"}
            >
              <UserFailures />
            </Col>
            <Col xs={user ? 3 : 3} xl={user ? 2 : 3} className="col colstatics">
              <UserSuccesses />
            </Col>
            <Col
              xs={user ? 3 : 12}
              xl={user ? 2 : 3}
              className="col colstatics"
            >
              <UserHints />
            </Col>
          </Row>
          <InfoPuzzle
            puzzle={puzzle}
            user={user}
            config={config}
            nextPuzzle={nextPuzzle}
            puzzleFinished={puzzleFinished}
            repeat={repeat}
            hint={hint}
            isHintClicked={isHintClicked}
            showHint={showHint}
            after_pgn={puzzle.current ? puzzle.current.after_pgn : ""}
            fen={gameFen}
          />
        </Col>
      </Row>
    </>
  );
}
