import React, { useState, useEffect } from "react";
import { getUserSuccesses } from "../integrations/APIClient"; // Updated function name

const UserSuccesses = () => {
  const [successCount, setSuccessCount] = useState(null); // Store the success count
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getUserSuccesses() // Updated function name
      .then((data) => {
        setSuccessCount(data.totalSuccessCount); // Assuming this is the correct response structure
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <span>Loading...</span>;
  if (error)
    return (
      <span>
        {" "}
        <h2>
          {successCount ? successCount : 0}
          <br />
          <img
            src="/happy.webp"
            alt="Descriptive text"
            className=" medal img-fluid iconess  "
          />
        </h2>
      </span>
    );

  return (
    <h2>
      {successCount !== null ? (
        <span>
          {successCount} <br></br>
          <img
            src="/happy.webp"
            alt="Descriptive text"
            className=" medal img-fluid iconess  "
          />
        </span> // Display total success count
      ) : (
        <span>No successes recorded.</span>
      )}
    </h2>
  );
};

export default UserSuccesses;
